import React from "react";
import ReactDOM from "react-dom";
import "react-tabs/style/react-tabs.css";
import Authentication from "./components/authentication/Authentication";
import "react-pro-sidebar/dist/css/styles.css";
import "react-step-progress-bar/styles.css";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route, useParams,
} from "react-router-dom";
import {LanguageMetaData, ParsingExampleHeader} from "../server/src/data/definitions";
import {getLanguages} from "./data-retrieval";
import "./index.css";
import {Redirect} from "react-router";
import jwt_decode from "jwt-decode";
import Main from "./components/Main";

interface IProps {
  languagesMetadata : LanguageMetaData[];
}

type ExamplesByLanguage = {[languageId:string]:ParsingExampleHeader[]};

interface LangAccess {
  key: string,
  name: string,
  description: string,
  goals: object[]
}

interface IState {
  examples: ExamplesByLanguage,
  langAccess: LangAccess[]
}

function RedirectToAuthIfNecessary() {
  /* If the user tried to access a route directly without being logged in, they will be redirect to log in page */
  const { path } = useParams<{ path: string }>();
  return <>
    {localStorage.getItem('token') === null
        ? <Redirect to={{ pathname: "/", search: path ? "?path=" + encodeURIComponent(path) : '' }} />
        : <></>}</>
}

class App extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      examples: {},
      langAccess: []
    }
  }
  
  componentDidMount(): void {
    console.log("component did mount");
      const timeout = setTimeout(() => {
        console.log("timeout");
        // 👇️ redirects to an external URL
        window.location.replace('https://playground.strumenta.com');
      }, 3000);

  }

  render() {
    return (
        <div className="app">
          {/* <HashRouter>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Authentication getUserType={this.getUserType} getData={this.getData} />
                </Route>
                <Route path="/:path+">
                  <RedirectToAuthIfNecessary />
                  <Main languagesMetadata={this.props.languagesMetadata} examples={this.state.examples} />
                </Route>
              </Switch>
            </Router>
          </HashRouter> */}
          <p style={{margin: "20px"}}>Parser Bench has been replaced by the Strumenta's Playground.
          <br/>We are going to redirect you there in 3 seconds.
          <br/><br/>
            <a href="https://playground.strumenta.com">Visit Strumenta's Playground</a>
          </p>
        </div>
    );
  };
}

ReactDOM.render(<App languagesMetadata={[]}/>, document.getElementById("root"))


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();